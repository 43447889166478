<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字:</label>
				<el-input v-model="searchKey" placeholder="优惠券名称"></el-input>
			</div>
			<!--优惠券类型  -->
			<div class="filter-item">
				<label class="label">优惠券类型:</label>
				<el-select v-model="type" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option
						v-for="item in typeOptions"
						:key="item.value"
						:label="item.lable"
						:value="item.value"
					></el-option>
				</el-select>
			</div>
			<!-- 优惠券状态 -->
			<div class="filter-item">
				<label class="label">优惠券状态:</label>
				<el-select v-model="status" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option
						v-for="item in statusOptions"
						:key="item.value"
						:label="item.lable"
						:value="item.value"
					></el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addVoucher'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleAddTable">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" style="width: 100%" v-loading="loading">
				<el-table-column prop="couponName" label="优惠券"></el-table-column>
				<el-table-column label="类型">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 0">满减券</span>
						<span v-if="scope.row.type == 1">随机金额券</span>
						<span v-if="scope.row.type == 2">打折券</span>
					</template>
				</el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<span v-if="scope.row.cardType == 0">进行中</span>
						<span v-if="scope.row.cardType == 1">已失效</span>
					</template>
				</el-table-column>
				<el-table-column prop="text" label="优惠内容"></el-table-column>
				<el-table-column prop label="剩余/总量" width="250px">
					<template slot-scope="scope" >
						<div>
							<div style="width: 130px;text-align: center;margin-left: 10px;color: #FF4D51;">{{scope.row.num}}</div>
							<!-- <el-progress :show-text="false" :percentage="scope.row.couponProgress"></el-progress> -->
							<div style="display: flex;align-items: center;">
								<div style="margin-right: 5px;">{{scope.row.drawNum}}</div>
								<div style="position: relative;width: 130px;height: 10px;">
									<div style="width: 130px;height: 6px;background:#EBEEF5;border-radius: 10px;position: absolute;top: 0;left: 0;"></div>
									<div :style="{width:scope.row.couponProgress+'px',}" style="height: 6px;background:#409EFF;border-radius: 10px;position: absolute;top: 0;left: 0;"></div>
								</div>
								<div style="margin-left: 5px;">{{scope.row.totalNum}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="drawNum" label="已领取"></el-table-column>
				<el-table-column prop="useNum" label="已使用"></el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="300px">
					<template slot-scope="scope">
						<div>		
							<button-permissions :datas="'editVoucher'">
								<div class="table-button" @click="handleTableEdit(scope.row,1)">编辑</div>
							</button-permissions>							
							<!-- <button-permissions :datas="'voucherTg'" v-if="scope.row.cardType !== 1">
								<div class="table-button" @click="handleTablePromote(scope.row.id)">推广</div>
							</button-permissions> -->
							
							<button-permissions :datas="'cancelVoucher'" v-if="scope.row.cardType !== 1">
								<div class="table-button" @click="handleTableFail(scope.row.id)">使失效</div>
							</button-permissions>
							
							<button-permissions :datas="'copyVoucher'">
								<div class="table-button" @click="handleTableCopy(scope.row)">复制</div>
							</button-permissions>
							<button-permissions :datas="'receiveCoupon'">
								<div class="table-button" @click="receiveCoupon(scope.row)">发放记录</div>
							</button-permissions>
							<button-permissions :datas="'deleteVoucher'">
								<div class="table-button" @click="handleTableDelete(scope.row.id)">删除</div>
							</button-permissions>
						</div>

					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				v-if="page.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="page.pageNo"
				:page-sizes="[10, 20, 30, 40, 50]"
				:page-size="page.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="page.total"
			></el-pagination>
		</div>
		<!-- add-dialog -->
		<!-- :before-close="handleClose" -->
		<el-dialog title="添加优惠券" :visible.sync="editDialog" width="40%" class="dialog">
			<div class="content">
				<div class="left">我要添加</div>
				<div class="right">
					<el-radio v-model="editRadio" :label="0">满减券</el-radio>
					<div class="text">可为用户提供减免订单金额服务，你可以设置“满X元减Y元”</div>
					<el-radio v-model="editRadio" :label="1">随机金额券</el-radio>
					<div class="text" style="line-height: 30px !important;">用户领取时，在随机金额区间内，随机生成减免金额，你可以设置“满X元减Y1~Y2元”</div>
					<el-radio v-model="editRadio" :label="2">打折券</el-radio>
					<div class="text">可为用户提供订单打折服务，你可以设置“满X元打Y折”</div>
				</div>
			</div>
			<div class="dialog-footer">
				<el-button class="button" @click="addCouponSure" type="primary">确定</el-button>
			</div>
		</el-dialog>
		
		<!-- 优惠券发放记录 -->
		<el-dialog :visible.sync="recordShow" width="85%" title="发放记录">
			<div>
				<div class="table-container">
					<el-table max-height="600" :data="recordList" style="width: 100%;" >
						<el-table-column prop="couponName" label="优惠券名称"></el-table-column>
						<el-table-column prop="userId"  label="领取人用户ID" width="220px"></el-table-column>
						<el-table-column prop="verificationCode"  label="优惠券编码" width="220px"></el-table-column>
						<el-table-column prop="realReduceAmount" label="面额"></el-table-column>
						<el-table-column prop="couponStatusValue" label="状态"></el-table-column>
						<el-table-column prop="createTime" label="领取时间"></el-table-column>
						<el-table-column prop="useTime" label="使用时间"></el-table-column>
					</el-table>
					<div style="display:flex;justify-content:space-between;align-items:center;">
						<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
						</div>
						<el-pagination v-if="Total1" style="margin-top:20px;float:right;" @size-change="handleSizeChange1"
						@current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]"
						:page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="Total1">
						</el-pagination>
					</div>
				</div>
			</div>
		</el-dialog>

		<!-- 推广的弹框 -->
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel"></batch-cods>
	</div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import batchCods from "@/components/bathCodes"
import apiList from '@/api/other';
import {
		findGrantCoupon,
	} from '@/api/wyUsedInterface.js'
export default {
	components: {
		buttonPermissions, batchCods
	},
	data () {
		return {
			weiCodeData: {},
			isMoreShow: false,
			weiCodeShow: false,
			bathSortDatas: [],
			searchKey: '',
			type: null,
			loading: false,
			typeOptions: [
				{
					lable: '满减券', value: 0
				},
				{
					lable: '打折券', value: 2
				},
				{
					lable: '随机金额券', value: 1,
				}
			],
			status: null,
			statusOptions: [
				{
					lable: '进行中', value: 0
				},
				{
					lable: '已失效', value: 1
				}
			],
			tableData: [],
			page: {
				total: 0,
				pageNo: 1,
				pageSize: 20
			},
			currentPage1: 1,
			pageSize1: 20,
			Total1: 0,
			coupondetailLading: false,
			couponStatistical: {
				FeeEffectRate: '%',
				NewCustomerCount: '0',
				OldCustomerCount: '0',
				OrderCount: '0',
				PayMoney: '0',
				PerferMoney: '0',
				ProductCount: '0',
				Results: [],
				Total: '',
				UseCouponUnitMoney: '0',
			},
			currentCouponName: '',
			// edit
			editDialog: false,
			editRadio: 0,
			showDataDialog: false,
			currentDataId: '',
			dialogPage: {
				total: 0,
				pageNo: 1,
				pageSize: 10
			},
			dialogTableData: [
				{ name: '1354' }
			],
			currentEditId: null,
			recordShow:false,
			recordList:[],
			currentRow:null,
		}
	},
	created () {
		this.getList()
	},
	methods: {
		// 获取列表数据
		async getList () {
			this.loading = true;
			try {
				let data = {
					keyWords: this.searchKey,
					type: this.type,
					cardType: this.status,
					pageNo: this.page.pageNo,
					pageSize: this.page.pageSize,
				}
				let result = await apiList.activitycouponCouponlist(data);
				this.page.total = result.data.total;
				result.data.records.map(item=>{
					// item.couponProgress = parseInt(item.num / item.totalNum*100);
					item.couponProgress = parseInt(item.num / item.totalNum*130);
					return item;
				})
				
				this.tableData = result.data.records;
			} catch (error) {
				console.log(error);
				this.loading = false
			} finally {
				this.loading = false;
			}
		},
		//添加优惠券
		handleAddTable () {
			this.$router.push({ path: '/market/coupon/editDiscountTicketDetail', query: { type: this.editRadio } })
			// this.editDialog = true
		},
		handleFilter () {
			this.page.pageNo = 1
			this.getList()
		},
		//新增优惠券
		addCouponSure () {
			this.$router.push({ path: '/market/coupon/editDiscountTicketDetail', query: { type: this.editRadio } })
		},
		//编辑，查看
		handleTableEdit (val, status) {
			// status:1:编辑 0：查看
			this.$router.push({ path: '/market/coupon/editDiscountTicketDetail', query: { id: val.id, type: +val.type, status: status } })
		},
		//复制
		handleTableCopy (val) {
			this.$router.push({ path: '/market/coupon/editDiscountTicketDetail', query: { copyId: val.id, type: val.type } })
		},
		//使失效
		handleTableFail (id) {
			this.$confirm('优惠券失效后，不会影响已经发放的优惠券，你将不可再对这张优惠券进行编辑，是否确认使失效？', '提示', {
				confirmButtonText: '确认失效',
				cancelButtonText: '关闭',
				type: 'warning'
			}).then(async () => {
				let result = await apiList.activitycouponActivitycouponsetisopen({
					id: id
				})
				if (result.success) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '失效成功!'
					});
					this.getList()
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: "操作失败，请重试"
					});
					this.getList()
				}

			}).catch(() => {
				this.$message({
					showClose: true,
					type: 'info',
					message: '已取消失效'
				});
			}).finally(() => {
				// this.getList()
			})
		},
		// table删除
		handleTableDelete (id) {
			this.$confirm('是否确认删除这张优惠券?删除后不可恢复', '提示', {
				confirmButtonText: '确认删除',
				cancelButtonText: '关闭',
				type: 'warning'
			}).then(async () => {
				let result = await apiList.activitycouponActivitycoupondelete({
					id: id
				})
				if (result.success) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '删除成功!'
					});
					this.getList()
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: result.alertMsg
					});
					this.getList()
				}


			}).catch(() => {
				this.$message({
					showClose: true,
					type: 'info',
					message: '已取消删除'
				});
			}).finally(() => {
				// this.getList();
			})
		},
		getRamNumber () {
			var result = '';
			for (var i = 0; i < 6; i++) {

				result += Math.floor(Math.random() * 16).toString(16);
			}

			return result;
		},
		// 推广-优惠券
		handleTablePromote (id) {
			this.weiCodeShow = true;
			// let uid = this.getRamNumber()
			this.weiCodeData = {
				Page: `pages/availableGoods/availableGoods`,
				Scene: `id=${id}`
			}
		},
		weiCodeCancel (val) {
			console.log(val);
			this.weiCodeShow = false;
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.page.pageSize = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.page.pageNo = val;
			this.getList();
		},
		// 切换显示条数
		handleDialogSizeChange (val) {
			this.dialogPage.pageSize = val;
			this.handleTableData();
		},
		// 翻页
		handleDialogCurrentChange (val) {
			this.dialogPage.pageNo = val;
			this.handleTableData();
		},
		// 切换显示条数
		handleSizeChange1(val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize1 = val;
			this.getDetail()
		},
		
		// 翻页
		handleCurrentChange1(val) {
			console.log(`当前页: ${val}`);
			this.currentPage1 = val;
			this.getDetail();
		},
		async getDetail(){
			try {
				this.listLoading = true;
				let data = {
					pageNo: this.currentPage1,
					pageSize: this.pageSize1,
					couponId:this.currentRow.id
				};
				let res = await findGrantCoupon(data);
				res.data.records.map(item=>{
					item.couponStatusValue = item.couponStatus==0 ?'待使用': (item.couponStatus==1?'已使用':'已过期');
					return item;
				})
				this.recordList = res.data.records;
				this.Total1 = res.data.total;
			} catch (e) {
				//TODO handle the exception
			}
		},
		receiveCoupon(row){
			this.currentRow = row;
			this.currentPage1 = 1,
			this.pageSize1 = 20,
			this.Total1 = 0,
			this.recordList = [];
			this.recordShow = true;
			this.getDetail();
		}
	}
}
</script>

<style  lang="less" scoped>
.app-container {
	/deep/.el-input {
		width: 220px;
	}
	background-color: #fff;
}
.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 15px;
	cursor: pointer;
}
.dialog {
	width: 100%;
	/deep/.el-dialog__header {
		border-bottom: 1px solid #ddd;
	}
	.content {
		padding: 0 30px;
		display: flex;
		flex-direction: row;
		.right {
			margin-left: 20px;
		}
		.text {
			margin-left: 20px;
			font-size: 14px;
			color: #999;
			line-height: 40px;
		}
	}
	.button {
		margin: 0 auto;
		width: 200px;
	}
}
.data-dialog {
	.value {
		line-height: 50px;
		font-size: 24px;
		color: #000;
	}
	.item {
		color: #666;
		margin-top: 10px;
	}
}
.dialog-footer {
	margin-top: 30px;
	text-align: center;
}
.el-pagination {
	/deep/.el-input {
		width: 100px;
	}
}
</style>
